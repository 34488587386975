.carousel-inner {
    height: 100% !important;
}

.text-warning-dark {
    color: #ff6700  !important;
}

.react-tooltip {
    z-index: 999999;
}

@media (max-width: 576px) {
    .w-xs-100 {
        width: 100% !important;
    }
}

.home-dark {
    background-color: #f7f7f7;
}

.home-darker {
    background-color: #e9e9e9;
}

.home-purple {
    background-color: #e9e9ff !important;
}
.home-darkest {
    background-color: #e1e1e1;
}

.dropdown-toggle::after {
    display: none !important;
}

.activity-dropdown {
    z-index: 999;
}

.loading-cursor {
    cursor: wait;
}

.home-gradient {
    background: rgb(255, 255, 255);
    background: linear-gradient(
        18deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(199, 174, 252, 0.4471989479385504) 58%,
        rgba(111, 110, 196, 0.6936975473783263) 100%
    );
}
.hover-shadow:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.25) !important;
}

.guide-card {
    cursor: pointer;
}
.guide-card:hover {
    cursor: pointer;
    box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.75) !important;
}

.border-on-hover:hover {
    border: 2px solid green;
    cursor: pointer;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.border-on-hover {
    border: 2px solid gray;
    cursor: pointer;
    box-shadow: none;
}

.account-card:hover {
    box-shadow: 0px 0px 10px 5px #ccc;
    cursor: pointer;
}

.stripe-card {
    height: 40px;
    padding: 12px 12px;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.cursor-pointer {
    cursor: pointer;
}
.App {
    height: 100vh;
}

.modal-vw80 {
    min-width: 80vw;
}

.not-authenticated-view {
    background: linear-gradient(
        90deg,
        #ffffff 0%,
        #f2edff 38.54%,
        #9391d4 100%
    );
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.modal-80w {
    min-width: 80vw !important;
}

.min-w-50 {
    min-width: 50% !important;
    max-width: 50% !important;
}
.sidebar {
    border-right: 1px solid #ccc !important;
}
.icon-wrapper {
    background-color: #fff !important;
    color: #bdacf3 !important;
}

.inner-list-item {
    background-color: #fff !important;
}

.sub-menu-content {
    background-color: #d0d0d0 !important;
}

.menu-label {
    font-size: 15px !important;
}

.sidebar-inner {
    color: #000 !important;
    background-color: #fff !important;
}

.menu-anchor {
    padding: 1.8rem !important;
}

.menu-icon {
    font-size: 14px;
}

.sidebar {
    min-width: 270px !important;
    width: 270px !important;
}

.menu-icon {
    justify-content: left !important;
    margin-right: 0px !important;
}

.mm-purple {
    background-color: #bdacf3 !important;
}

.hover-darken:hover {
    background-color: #f5f5f5;
}

.hover-darken:active {
    background-color: #e0e0e0;
}

.darken {
    background-color: #f0f0f0;
}

.purple-dark {
    background-color: #6f6ec4 !important;
}

.btn-purple-dark-outline {
    border: 1px solid #6f6ec4 !important;
}

.btn-purple-dark-outline:hover {
    background-color: #6f6ec4 !important;
    color: white !important;
}

.bg-gold {
    background-color: #eebc1d !important;
    text: white;
}

.bg-silver {
    background-color: silver;
    text: darkgray;
}

.text-purple {
    color: #bdacf3 !important;
}
.text-purple-dark {
    color: #6f6ec4 !important;
}

.dimmed-background {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 4px;
}

.dimmed-background:hover {
    background-color: rgba(0, 0, 0, 0.625);
    border-radius: 4px;
}

.dim {
    filter: brightness(70%);
}

.card-overlay {
    background: linear-gradient(
        90deg,
        rgba(52, 232, 27, 0) 0%,
        rgba(78, 78, 78, 0.5) 50%,
        rgba(0, 0, 0, 0.8) 100%
    );
}

.card-img-overlay {
    height: 88px !important;
}

.card-button {
    font-weight: 500;
    text-align: left !important;
    color: #bdacf3 !important;
}

.btn-card-purple {
    text-align: left !important;
    color: #bdacf3 !important;
    border-radius: 4px !important;
}

.btn-card-purple:hover {
    background-color: #fafafa !important;
    border: 1px solid #bdacf3 !important;
    border-radius: 4px !important;
}

.text-bg-purple-dark {
    text-align: left !important;
    background-color: #e0d6fb !important;
    border-radius: 4px !important;
}

.btn-card-success {
    text-align: left !important;
    color: #198754 !important;
    border-radius: 4px !important;
}

.btn-card-success:hover {
    background-color: #fafafa !important;
    border: 1px solid #198754 !important;
    color: #198754 !important;
    border-radius: 4px !important;
}

.no-pointer-events {
    pointer-events: none;
}

.alert-purple-dark {
    text-align: left !important;
    background-color: #6f6ec4 !important;
    color: #fff !important;
    text-decoration: none;
    border-radius: 4px !important;
}

.btn-card-purple-dark {
    text-align: left !important;
    color: #6f6ec4 !important;
    border-radius: 4px !important;
}

/* .btn-transparent {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
} */

.btn-card-purple-dark:hover {
    background-color: #fafafa !important;
    border: 1px solid #6f6ec4 !important;
    color: #6f6ec4 !important;
    border-radius: 4px !important;
}

.badge-purple {
    background-color: #6f6ec4 !important;
}

.btn-purple {
    text-align: left !important;
    background-color: #bdacf3 !important;
    color: #fff !important;
}

.btn-purple-dark {
    background-color: #6f6ec4 !important;
    color: #fff !important;
}

.btn-purple-dark:hover {
    background-color: #7e81c6 !important;
    color: #fff !important;
}

.btn-purple-dark:active {
    background-color: #323a3f !important;
    color: #fff !important;
}

.btn-purple-light {
    text-align: left !important;
    background-color: #e0d6fb !important;
    color: #333 !important;
}

.info-button:hover {
    color: #0000ff !important;
}

.trash-button:hover {
    color: #dc3545 !important;
}

#dragover-area:hover {
    background-color: #f3f3f3;
    cursor: pointer;
}

.disable-pointer-events {
    pointer-events: none !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.disabled-pointer {
    cursor: default;
    pointer-events: none;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.form-card-light {
    background-color: #f3f3f3;
}

.form-card-white {
    background-color: #fff;
}

.form-card-white:hover {
    background-color: #f9f9f9;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    cursor: pointer;
}

.form-card-light:hover {
    background-color: #f9f9f9;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    cursor: pointer;
}

.disabled-card-home {
    background-color: #f0f0f0 !important;
    pointer-events: none;
    cursor: default;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.disabled-card {
    background-color: #000;
    filter: brightness(30%);
    pointer-events: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.bg-purple {
    background-color: #bdacf3 !important;
}

.bg-purple-dark {
    background-color: #6f6ec4 !important;
}

main {
    overflow: auto;
}

@media only screen and (max-width: 992px) {
    .stack-on-sm-btn {
        text-align: left !important;
        display: block;
        margin-bottom: 5px;
        float: none;
        width: 100%;
    }
}

@-webkit-keyframes Animation {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}
@-moz-keyframes Animation {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}
@keyframes Animation {
    0% {
        background-position: 10% 0%;
    }
    50% {
        background-position: 91% 100%;
    }
    100% {
        background-position: 10% 0%;
    }
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

@keyframes rotate {
    to {
        --angle: 360deg;
    }
}

.btn-premium {
    background: linear-gradient(
        157deg,
        rgba(255, 199, 71, 1) 15%,
        rgba(202, 166, 255, 1) 58%,
        rgba(111, 110, 196, 1) 87%
    );
    background-size: 200% 200%;

    color: #fff !important;

    -webkit-animation: Animation 3s ease-in-out infinite !important;
    -moz-animation: Animation 3s ease-in-out infinite !important;
    animation: Animation 3s ease-in-out infinite !important;
    border: none !important;

    animation-direction: alternate;
}

.btn-premium:hover {
    background-color: #7e81c6 !important;
    color: #fff !important;
}

.btn-premium:active {
    background-color: #323a3f !important;
    color: #fff !important;
}

.btn-upgrade {
    background: linear-gradient(
        157deg,
        rgba(142, 255, 162, 1) 16%,
        rgba(109, 231, 66, 1) 44%,
        rgba(52, 232, 27, 1) 71%,
        rgba(130, 196, 110, 1) 100%
    );
    background-size: 200% 200%;

    color: #fff !important;

    -webkit-animation: Animation 5s ease-in-out infinite !important;
    -moz-animation: Animation 5s ease-in-out infinite !important;
    animation: Animation 5s ease-in-out infinite !important;
    border: none !important;

    animation-direction: alternate;
}

.border-dotted {
    border-style: dotted !important;
}

.dragover-area {
    display: none;
    width: 100%;
    color: white;
    flex-direction: column;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 999999999;
    justify-content: center;
    align-items: center;
}

/* Override bootstrap colors */
.pagination > li > a {
    background-color: white;
    color: #5a4181;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
    color: #5a5a5a;
    background-color: #eee;
    border-color: #ddd;
}

.pagination > .active > a {
    color: white;
    background-color: #5a4181 !important;
    border: solid 1px #5a4181 !important;
}
.pagination > .active > a {
    color: white;
    background-color: #5a4181 !important;
    border: solid 1px #5a4181 !important;
}

.pagination > li > span {
    background-color: #6f6ec4 !important;
    border: solid 1px #0f0;
}

.pagination > li > span {
    border: solid 1px #6f6ec4 !important;
}

.pagination > .active > a:hover {
    background-color: #5a4181 !important;
    border: solid 1px #5a4181;
}
